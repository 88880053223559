const privillegesTab = {
  "PRINT MODELS": "129f737db7c141a48ff176c6f1799f1f",
  "UPDATE MODELS": "22567448cbba4b5aac2389677893cd36",
  "DELETE MODELS": "76ae2e40827f482aac98b94caabce2ec",
  "CONSULT MODELS": "fdbd4e2958934bc583c8cde88a16a83c",
  "CREATE WORKSPACES": "b83310e6b8a0458c8fa4f671a212b104",
  "DELETE WORKSPACES": "8349f747ef8146668506e5e3d3bd7bb8",
  "CONSULT WORKSPACES": "72bf1a63070b49f8bf8a9cc47d63dbb0",
  "UPDATE WORKSPACES": "3a97a9ea11ac4ebea164c64a0b4555d3",
  "CREATE MODELS": "33521c9ed0674437af9b0e6d5395036e"
};
export default privillegesTab;
