<script>
  import {  createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  let rootElement;
  export let width;
  export let height;
  $: rootElement && rootElement.style.setProperty("--container-width", width);
  $: rootElement && rootElement.style.setProperty("--container-height", height);
</script>

<input type="date" id="dateofbirth" bind:this={rootElement} on:change={(e)=>{
  dispatch('pick',{
    date: e.target.value
  })
}} />

<style>
  :root {
    --container-width: inherit;
    --container-height: inherit;
    --container-border-radius: 5px;
    --container-border: 1px solid #c4c4c4;
    --container-bg-color: #fff;
    --container-font: Arial, Helvetica, sans-serif;
    --container-font-size: 16px;
  }

  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  
  [type="date"]::-webkit-calendar-picker-indicator {
    opacity:0.7;
    width: var(--container-font-size);
    height: var(--container-font-size);
    border-radius: var(--container-border-radius);
  }

  input[type="date"] {
    outline: none;
    border: var(--container-border);
    border-radius: var(--container-border-radius);
    background-color: var(--container-bg-color);
    width: var(--container-width);
    height: var(--container-height);
    overflow:hidden;
    font-family: var(--container-font);
    font-size: var(--container-font-size);
  }

  input[type="date"]:hover{
    filter: brightness(95%);
  }

  input[type="date"]:focus {
    box-shadow: 0px 0px 2px 1px rgba(66,153,225,0.8);
    border: 1px solid rgba(66,153,225,0.3);
  }
</style>
