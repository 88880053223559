export function StartWorksapceTutorial() {
  let first_time = localStorage.getItem("StartWorksapceTutorial");
  if (!first_time) {
    let workspace = document.querySelector(".borderDiv");
    let accessBtn = document.querySelector(".accessBtn");
    if (workspace && accessBtn) {
      introJs()
        .setOptions({
        dontShowAgain: true,
        steps: [
            {
              title: "Welcome",
              intro: "Let's get started with a quick tour",
            },
            {
              element: document.querySelector(".projectBtn"),
              intro: "Click here to create new workspace",
            },
            {
              element: workspace,
              intro: "This is your demo workspace",
            },
            {
              element: accessBtn,
              intro: "Click here to access your workspace",
            },
          ],
        })
        .oncomplete(function () {
          localStorage.setItem("StartWorksapceTutorial", "passed");
        })
        .start();
    }
  }
}

export function StartModelTutorial() {
  let first_time = localStorage.getItem("StartModelTutorial");
  if (!first_time) {
    let createButton = document.querySelector(".createButton");
    let updateBtn = document.querySelector(".updateBtn");
    if (createButton && updateBtn) {
      introJs().addHints();
      introJs()
        .setOptions({
        dontShowAgain: true,
        steps: [
            {
              title: "Starting",
              intro: "Let's create new model",
            },
            {
              element: createButton,
              intro: "Click here to create new model",
            },
            {
              element: updateBtn,
              intro: "Click here to update your demo model",
            },
          ],
        })
        .oncomplete(function () {
          localStorage.setItem("StartModelTutorial", "passed");
        })
        .start();
    }
  }
}

export function StartModelingTutorial() {
  let first_time = localStorage.getItem("StartModelingTutorial");
  if (!first_time) {
    let importBtn = document.querySelector(".importBtn");
    let nameInput = document.querySelector(".nameInput");
    let saveBtn = document.querySelector(".saveBtn");
    introJs().addHints();
    introJs()
      .setOptions({
        dontShowAgain: true,
        steps: [
          {
            element: importBtn,
            intro: "Import your pdf file.",
          },
          {
            element: nameInput,
            intro: "Enter your model name here .",
          },
          {
            element: saveBtn,
            intro:
              "After upload and naming the model you have to save your changes.",
          },
        ],
      })
      .oncomplete(function () {
        localStorage.setItem("StartModelingTutorial", "passed");
      })
      .start();
  }
}

export function StartUpdateModelTutorial() {
  let first_time = localStorage.getItem("StartUpdateModelTutorial");
  if (!first_time) {
    let outilsBars = document.querySelector(".outilsBars");
    let addFieldBtn = document.querySelector(".addFieldBtn");
    introJs().addHints();
    introJs()
      .setOptions({
        dontShowAgain: true,
        steps: [
          {
            element: outilsBars,
            intro: "This is sidebar where you will edit added field.",
          },
          {
            element: addFieldBtn,
            intro: "Click here to add new field then double click on it to edit.",
          }
        ],
      })
      .oncomplete(function () {
        localStorage.setItem("StartUpdateModelTutorial", "passed");
      })
      .start();
  }
}


export function StartSideBarTutorial() {
  let first_time = localStorage.getItem("StartSideBarTutorial");
  if (!first_time) {
    let idFieldInput = document.querySelector(".idFieldInput");
    let lineHeightInput = document.querySelector(".lineHeightInput");
    let typeInput = document.querySelector(".typeInput");
    let fontSizeInput = document.querySelector(".fontSizeInput");
    let fontFamilyInput = document.querySelector(".fontFamilyInput");
    let policeTypeInput = document.querySelector(".policeTypeInput");
    let allowedNumber = document.querySelector(".allowedNumber");
    let LivePrintInput = document.querySelector(".LivePrintInput");
    introJs()
      .setOptions({
        disableInteraction: true,
        dontShowAgain: true,
        steps: [
          {
            element: idFieldInput,
            intro: "Identify your field here.",
            position:"right"
          },
          {
            element: lineHeightInput,
            intro: "Identify your text Line height.",
            position:"right"
          },{
            element: typeInput,
            intro: "Identify your text type.",
            position:"right"
          },
          {
            element: fontSizeInput,
            intro: "Identify your font size.",
            position:"right"
          },{
            element: fontFamilyInput,
            intro: "Identify font family.",
            position:"right"
          },{
            element: policeTypeInput,
            intro: "Identify Police type.",
            position:"right"
          },{
            element: allowedNumber,
            intro: "Identify Allowed number of characters.",
            position:"right"
          },{
            element: LivePrintInput,
            intro: "Activate this to display this field when you generate the url.",
            position:"right"
          },
        ],
      })
      .oncomplete(function () {
        localStorage.setItem("StartSideBarTutorial", "passed");
      })
      .start();
  }
}