<script>
  export let options = [];
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  let rootElement;
  export let width;
  export let height;
  $: rootElement && rootElement.style.setProperty("--container-width", width);
  $: rootElement && rootElement.style.setProperty("--container-height", height);
</script>

<select
  class="form-select"
  bind:this={rootElement}
  on:change={(e) => {
    dispatch("select", {
      value: e.target.value,
    });
  }}
>
  <option value="" selected>Choisir dans la liste</option>
  {#each options as option}
    <option value={option.text}>{option.text}</option>
  {/each}
</select>

<style>
  :root {
    --container-width: inherit;
    --container-height: inherit;
  }
  select {
    display: block;
    top: 0;
    left: 0;
    height: var(--container-height);
    width: var(--container-width);
  }
  .form-select {
    border-radius: 0.5rem;
    background-color: #959b9e4d;
    color: #4a5568;
    font-size: 1rem;
    cursor: pointer;
  }

  .form-select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  }
  
</style>
