<script>
  import axios from "axios";
  import { closeModal, modals } from "svelte-modals";
  import SimpleCrypto from "simple-crypto-js";
  import { onMount } from "svelte";
  import Swal from "sweetalert2";
  import { v4 as uuidv4 } from "uuid";
  import Dropzone from "svelte-file-dropzone";
  // import DropZone from "svelte-atoms/DropZone.svelte";
  import { getAsset } from "../utils/prepareAssets";
  import { readAsArrayBuffer } from "../utils/asyncReader";
  import { _ } from "../services/i18n"
  export let onOpenAnother;

  export let isOpen;
  let messageSucces = "";
  let stackIndex = modals.length;
  let workspacename = "";
  let company_id = localStorage.getItem("company_id");
  // const AddWorkspace = (e) => {
  //   e.preventDefault();
  //   let simpleCrypto = new SimpleCrypto("process.env.SECRET_KEY");
  //   let usid = localStorage.getItem("_");
  //   let uid = simpleCrypto.decrypt(usid);
  //   axios
  //     .post(process.env.BASE_URL +"/workspace/create", {
  //       user_id: uid,
  //       name: workspacename,
  //     })
  //     .then((res) => {
  //       closeModal();
  //       window.location.reload();
  //     })
  //     .catch((err) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Erreur!",
  //       });
  //     });
  // };
  var file = {};
  var message = "";

  async function createNewModel(data, fileselected) {
    try {
      let simpleCrypto = new SimpleCrypto(process.env.SVELTE_APP_SECRET_KEY);
      let userdata = localStorage.getItem("-");
      let workspace = simpleCrypto.decrypt(userdata);
      const file = new FormData();
      file.append("file", fileselected);
      file.append("workspace_id", workspace.id);
      let uuid = uuidv4();
      axios
        .post(
          process.env.BASE_URL +"/editique_pdf/upload",
          file,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((res) => {
          axios
            .post(
              process.env.BASE_URL +"/editique_pdf/setFields",
              {
                data: JSON.parse(data),
                fileurl: res.data.file_url,
                idModel: uuid,
                modelName: $_('workspace.Exported File') + Date.now(),
                workspace_id: workspace.id,
                company_id: company_id,
              }
            )
            .then((res) => {
              window.location.href = "/HomeWorkspace";
              
            })
            .catch((error) => console.log(error));
        })
        .catch((err) =>
          Swal.fire({
            icon: "error",
            // title: "Oops...",
            text: $_('workspace.Failed to import model')
          })
        );
    } catch (e) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Verifier l'intégrité  de votre fichier"),
      });
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Failed to add pdf"),
      });
      throw e;
    }
  }
  async function importPDF(e) {
    e.preventDefault();

    try {
      const PDFLib = await getAsset("pdfjsLib");
      const data = await readAsArrayBuffer(file);
      let document = PDFLib.getDocument({ data })
      document.promise.then((promise) => {
        promise.getMetadata().then((data) => {
          if (data.info.Keywords) {
            createNewModel(data.info.Keywords, file);
            closeModal();
          } else {
            message = $_("workspace.il faut choisir un pdf exporté");
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  let files = {
    accepted: [],
    rejected: [],
  };

  function handleFilesSelect(e) {
    const { acceptedFiles, fileRejections } = e.detail;
    files.accepted = [...files.accepted, ...acceptedFiles];
    files.rejected = [...files.rejected, ...fileRejections];
    if (acceptedFiles[0].type !== "application/pdf") {
      message = $_("workspace.Vous devez charger un fichier PDF");;
    } else {
      file = acceptedFiles[0];
      message = acceptedFiles[0].path;
    }
  }
</script>

{#if isOpen}
  <!-- <div role="dialog" class="modal">
        <div class="contents">
          <h2>{title}</h2>
          <p>{message}</p>
          <div
            class="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
    
            <div class="fixed inset-0 z-10 overflow-y-auto">
              <div
                class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
              >
                <div
                  class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                      <div
                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                      >
                        <svg
                          class="h-6 w-6 text-red-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                          />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                          class="text-lg font-medium leading-6 text-gray-900"
                          id="modal-title"
                        >
                          Creation d'un espace de travail
                        </h3>
                        <div class="mb-6">
                          <label
                            for="default-input"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >Nom d'espace</label
                          >
                          <input
                            type="text"
                            id="default-input"
                            bind:value={workspacename}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                  >
                    <button
                      type="button"
                      on:click={AddWorkspace}
                      class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      >Confirmer</button
                    >
                    <button
                      type="button"
                      class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      on:click={closeModal}>Cancel</button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
  <div role="dialog" class="modal" id="modal">
    <div class="contents">
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <!--
            Background backdrop, show/hide based on modal state.
        
            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0"
          -->
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <!--
                Modal panel, show/hide based on modal state.
        
                Entering: "ease-out duration-300"
                  From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                  From: "opacity-100 translate-y-0 sm:scale-100"
                  To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              -->
            <div
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg items-center"
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="">
                  <div
                    class="mt-3 text-center sm:mt-0  flex-col justify-center items-center centerClass"
                  >
                    <h1
                      class="purpleColor text-sm font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                    {$_('workspace.ImportModel')}
                    </h1>
                    <div class="mt-4 mb-4 cursor-pointer ">
                      <Dropzone
                        accept="application/pdf"
                        on:drop={handleFilesSelect}
                      >
                        <svg
                          width="65"
                          height="61"
                          viewBox="0 0 65 61"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M37.2162 38.168H27.7837C23.7091 38.168 21.2708 40.6063 21.2708 44.6809V54.1134C21.2708 58.188 23.7091 60.6263 27.7837 60.6263H37.2162C41.2908 60.6263 43.7291 58.188 43.7291 54.1134V44.6809C43.7291 40.6063 41.2908 38.168 37.2162 38.168ZM40.232 50.7767C39.9112 51.0975 39.4299 51.3221 38.9166 51.3542H34.3928L34.4249 55.8138C34.3928 56.3592 34.2003 56.8084 33.8153 57.1934C33.4945 57.5142 33.0133 57.7388 32.4999 57.7388C31.4412 57.7388 30.5749 56.8725 30.5749 55.8138V51.3221L26.0833 51.3542C25.5698 51.3458 25.0802 51.1359 24.7201 50.7698C24.36 50.4037 24.1582 49.9107 24.1583 49.3971C24.1583 48.3384 25.0245 47.4721 26.0833 47.4721L30.5749 47.5042V43.0125C30.5749 41.9538 31.4412 41.0555 32.4999 41.0555C33.5587 41.0555 34.4249 41.9538 34.4249 43.0125L34.3928 47.4721H38.9166C39.9753 47.4721 40.8416 48.3384 40.8416 49.3971C40.8089 49.9148 40.5927 50.404 40.232 50.7767Z"
                            fill="#945FAF"
                          />
                          <path
                            d="M63.749 29.6658C61.7919 23.2491 56.9152 18.6291 50.7873 17.2495C48.9907 9.22869 44.0498 3.48578 37.0557 1.30411C29.4198 -1.03797 20.5648 1.23995 15.0144 7.04703C10.1057 12.1483 8.5015 19.1745 10.3944 26.6499C3.56067 28.3183 0.416504 34.2858 0.416504 39.6758C0.416504 45.7074 4.36275 52.477 13.1536 53.0866H21.2707V44.6487C21.2707 40.5741 23.709 38.1358 27.7836 38.1358H37.2161C41.2907 38.1358 43.729 40.5741 43.729 44.6487V53.0866H46.4561C51.0119 53.0866 55.3752 51.3862 58.7119 48.3383C63.9415 43.7824 65.8665 36.6279 63.749 29.6658Z"
                            fill="#D9D9D9"
                          />
                        </svg>

                        <p class=" purpleColor text-sm text-gray-500 mb-6">
                          {$_('workspace.glisserFile')}
                        </p>
                      </Dropzone>
                    </div>

                    <p class="size">
                      {$_('workspace.tailleFile')}
                    </p>
                  </div>
                </div>
                <p class="text-xs text-red-500	">{message ? message : ""}</p>

                <div class=" centerClass  py-3  ">
                  <!--  <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >Copier</button
                > -->
                  <button
                    type="button"
                    on:click={closeModal}
                    class="annulerBtn mt-3  w-full  rounded-md  px-4 py-2  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >{$_('workspace.annuler')}</button
                  >
                  <button
                    type="button"
                    on:click={importPDF}
                    class="confirmerBtn mt-3  w-full  rounded-md b px-4 py-2   sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >{$_('workspace.confirmer')}</button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    /* allow click-through to backdrop */
    pointer-events: none;
  }

  .contents {
    min-width: 240px;
    border-radius: 6px;
    padding: 16px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: auto;
  }

  h2 {
    text-align: center;
    font-size: 24px;
  }

  p {
    text-align: center;
    margin-top: 16px;
  }
  .centerClass {
    text-align: -webkit-center;
  }
  .btn {
    margin: 2rem;
    background-color: #d9d9d9;
    color: #7e7e7e;
    font-weight: 500;
    font-size: 16px;
    font-family: Roboto;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 60%;
  }
  .size {
    color: #7e7e7e;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
  }
  .purpleColor {
    color: #945faf;
  }
  .annulerBtn {
    background-color: #7e7e7e;
    color: white;
  }
  .confirmerBtn {
    background-color: #945faf;
    color: white;
  }
</style>
