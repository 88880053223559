<script>
  import axios from "axios";
  import { navigate } from "svelte-routing";
  import Swal from "sweetalert2";
  import SimpleCrypto from "simple-crypto-js";
  import { onMount } from "svelte";
  import { workspace } from "../../store/store";

  import { _, setupI18n } from "../../services/i18n";
  import LocalStorageService from "../../utils/axios/localStorageService";
  import LoadingPdf from "../../assets/svg/LoadingPdf.svelte";
  const countries = [
    {
      name: "Anglais",
      flagUrl:
        "https://sdn.geoprod.com/static/uploads/organismes/logo/1680695875.754732united-kingdom_1.png",
      value: "en",
    },
    {
      name: "Français",
      flagUrl:
        "https://sdn.geoprod.com/static/uploads/organismes/logo/1680695875.754732france_1.png",
      value: "fr",
    },
  ];

  let name = "";
  let password = "";
  const urlParams = new URLSearchParams(window.location.search);
  let defaultLanguage = window.navigator.language.substr(0, 2);
  let selectedCountry =
    countries.find((country) => country.value === defaultLanguage) ||
    countries[0];

  let isOpen = false;

  let LoadingPage = true;

  function toggleDropdown() {
    isOpen = !isOpen;
  }

  function selectCountry(country) {
    selectedCountry = country;
    isOpen = false;
  }
  const token = urlParams.get("token");
  let wk = urlParams.get("id");
  function showPassword() {
    var passwordType = document.getElementById("myInput");
    var show = document.getElementById("show");
    var hide = document.getElementById("hide");
    if (passwordType.type === "password") {
      passwordType.type = "text";
      hide.classList.remove("hidden");
      show.classList.add("hidden");
    } else {
      passwordType.type = "password";
      hide.classList.add("hidden");
      show.classList.remove("hidden");
    }
  }

  onMount(() => {
    if (token) {
      localStorage.setItem("access_token", token);
      if (wk) {
        let decoded = atob(wk);
        let simpleCrypto = new SimpleCrypto(process.env.SVELTE_APP_SECRET_KEY);
        let wrks = wk && simpleCrypto.decrypt(decoded);
        var config = {
          method: "get",
          url: process.env.BASE_URL + "/workspace/workspaces",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        };
        axios(config).then(async (res) => {
          let result = res.data.find((e) => e.id === wrks);
          localStorage.setItem("-", simpleCrypto.encrypt(result));
          workspace.set(simpleCrypto.encrypt(result));
          var config2 = {
            method: "get",
            url: process.env.BASE_URL + "/auth/company",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          };
          axios(config2).then((res) => {
            localStorage.setItem("api_key", res.data.user.api_key);
            localStorage.setItem("user", res.data.user.workspaces.fullname);
            localStorage.setItem("refresh_token", res.data.user.refresh);
            localStorage.setItem("company_id", res.data.user.company_id);
            localStorage.setItem(
              "privilleges",
              simpleCrypto.encrypt(res.data.user.previleges)
            );
            window.location  = "/HomeWorkspace"
          });
        });
      }
    } else {
      LoadingPage = false;
      if (LocalStorageService.getAccessToken()) {
        window.location = "/Workspaces";
      }
    }
  });

  function Login(e, role) {
    if (name && password) {
      e.preventDefault();
      axios
        .post(process.env.BASE_URL + "/auth/login", {
          fullname: name,
          password: password,
          identifier: role == "admin" ? "company" : "worker",
        })
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("access_token", response.data.user.access);
            localStorage.setItem("refresh_token", response.data.user.refresh);
            if (role == "admin") {
              localStorage.setItem("company_id", response.data.user.company_id);
              let simpleCrypto = new SimpleCrypto(
                process.env.SVELTE_APP_SECRET_KEY
              );
              localStorage.setItem(
                "_",
                simpleCrypto.encrypt(response.data.user.company_id)
              );
              localStorage.setItem("api_key", response.data.user.api_key);
              localStorage.setItem(
                "user",
                response.data.user.workspaces.fullname
              );
              localStorage.setItem(
                "privilleges",
                simpleCrypto.encrypt(response.data.user.previleges)
              );
              window.location = "/Workspaces";
            } else {
              if (response.data.user.worker.active) {
                localStorage.setItem(
                  "company_id",
                  response.data.user.company_id
                );
                let simpleCrypto = new SimpleCrypto(
                  process.env.SVELTE_APP_SECRET_KEY
                );
                localStorage.setItem(
                  "_",
                  simpleCrypto.encrypt(response.data.user.company_id)
                );
                localStorage.setItem(
                  "api_key",
                  response.data.user.worker.api_key
                );
                localStorage.setItem(
                  "user",
                  response.data.user.worker.fullname
                );
                localStorage.setItem(
                  "privilleges",
                  simpleCrypto.encrypt(response.data.user.worker.previleges)
                );

              window.location = "/Workspaces";
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",

            text: $_("workspace.Wrong credentials"),
          }).then((result) => {
            if (result.isConfirmed) {
              name = "";
              password = "";
            }
          });
        });
    }
  }
</script>

{#if LoadingPage == true}
  <LoadingPdf />
{:else}
  <div
    class="bg-no-repeat bg-cover bg-center relative"
    style="background-image: url(https://img.freepik.com/free-vector/winter-blue-pink-gradient-background-vector_53876-117276.jpg);"
  >
    <div
      class="absolute bg-gradient-to-b from-green-500 to-green-400 opacity-75 inset-0 z-0"
    />
    <div class="dropdown">
      <div class="dropdown-button" on:click={toggleDropdown}>
        <img
          src={selectedCountry.flagUrl}
          alt={selectedCountry.value}
          style="width:2rem ; margin-right:1rem"
        />
        <span>{selectedCountry.name}</span>
      </div>

      {#if isOpen}
        <div class="dropdown-menu">
          {#each countries as country}
            <div
              class="dropdown-item"
              on:click={() => {
                selectCountry(country);
                localStorage.setItem("language", country.value);
                setupI18n({ withLocale: localStorage.getItem("language") });
              }}
            >
              <img
                src={country.flagUrl}
                alt={country.value}
                style="width:2rem ; margin-right:1rem"
              />
              <span>{country.name}</span>
            </div>
          {/each}
        </div>
      {/if}
    </div>

    <div class="min-h-screen sm:flex sm:flex-row mx-0 justify-center">
      <div
        class="flex-col flex self-center p-10 sm:max-w-5xl xl:max-w-2xl z-10"
        style="color:black;"
      >
        <div class="self-start hidden lg:flex flex-col text-white">
          <h1 class="mb-3 font-bold text-5xl text-gray-800">
            {$_("workspace.Hi Welcome To PDF STUDIO API")}
          </h1>
          <p class="pr-3 text-gray-800">
            Lorem ipsum is placeholder text commonly used in the graphic, print,
            and publishing industries for previewing layouts and visual mockups
          </p>
        </div>
      </div>

      <div class="flex justify-center self-center z-10">
        <div class="p-12 bg-white mx-auto rounded-2xl w-100">
          <div class="mb-4">
            <h3 class="font-semibold text-4xl text-gray-800">
              {$_("workspace.Sign In")}
            </h3>
            <p class="text-gray-500">
              {$_("workspace.Please sign in to your account")}
            </p>
          </div>
          <div class="space-y-5">
            <div class="space-y-2">
              <label class="text-sm font-medium text-gray-700 tracking-wide"
                >{$_("workspace.Username")}</label
              >
              <input
                class=" w-full text-base px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-400"
                type=""
                bind:value={name}
                placeholder={$_("workspace.Enter your username")}
              />
            </div>
            <div class="space-y-2">
              <label
                class="mb-5 text-sm font-medium text-gray-700 tracking-wide"
              >
                {$_("workspace.Password")}
              </label>
              <input
                class="w-full content-center text-base px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-400"
                type="password"
                bind:value={password}
                placeholder={$_("workspace.Enter your password")}
              />
            </div>

            <div>
              <button
                type="submit"
                class="w-full flex justify-center bg-gray-800 hover:bg-gray-600 text-gray-100 p-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
                on:click={(e) => Login(e, "admin")}
              >
                {$_("workspace.Sign in as an Admin")}
              </button>
            </div>
            <div>
              <button
                type="submit"
                class="w-full flex justify-center bg-gray-800 hover:bg-gray-600 text-gray-100 p-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
                on:click={(e) => Login(e, "worker")}
              >
                {$_("workspace.Sign in as a Worker")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .dropdown {
    width: 11%;
    padding-top: 1rem;
    margin-left: 1rem;
    position: relative;
  }
  .dropdown-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    /* border: 1px solid #ccc; */
    border-radius: 0.5rem;
    background-color: #c1cddf;
    user-select: none;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    max-height: 15rem;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #c1cddf;
    border-radius: 0.5rem;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: white;
    user-select: none;
  }
  .dropdown-item:hover {
    background-color: #c1cddf;
  }
  .dropdown-item img {
    margin-right: 0.5rem;
  }
</style>
