<script>
    import axios from "axios";
    import { closeModal, closeAllModals, openModal, modals } from "svelte-modals";
    import { _ } from "../services/i18n"
    import Swal from "sweetalert2";
    export let isOpen;
    export let model;
  
   
 
  
    

    async function DeleteModal(id) {
   
   var data = JSON.stringify({
     id: id,
   });

   var config = {
     method: "delete",
     url: process.env.BASE_URL +"/editique_pdf/delete_modal",
     headers: {
       "Content-Type": "application/json",
       Authorization: "Bearer " + localStorage.getItem("access_token"),
     },
     data: data,
   };

   axios(config)
     .then((res)=>{
       closeModal()
     Swal.fire({
         icon: "success",
         text:  $_('workspace.alertDeleteModel'),
     
       }).then(()=> window.location.reload())
     }
   
          
         )
     .catch(function (error) {
       closeModal()
       console.log(error);
       Swal.fire({
          icon: "error",
          // title: "Oops...",
          text:  $_('workspace.Échec de suppression du modèle'),
        });

     });
 

}

</script>

<!-- {#if isOpen} -->
<div role="dialog" class="modal">
  <div class="contents">
    <div>
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div class="fixed inset-0 z-10 ">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
        
              <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg bg-[url('assets/images/bg.png')]"
              >
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                   
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 ">
                      <h3
                        style="color:#945FAF;font-size:1.3rem;font-weight:500;font-family:Roboto"
                        id="modal-title"
                      >
                      {$_('workspace.supprimerModéle')}
                      </h3>
                      <div class="m-6">
                        <label
                          for="default-input"
                          style="color: #7E7E7E; font-size:1rem;font-weight:500;font-family:Roboto"
                          >{$_('workspace.textSupprimerModéle')}</label
                        >
                       
                      </div>
                     
                <div
                  class="  pb-6 text-center "
                >
                  <button
                    type="button"
                    on:click={closeModal}
                    style="background-color:#7E7E7E; width:8rem; padding-block:0.5rem;color:white;border-radius:0.5rem"
                    >{$_('workspace.non')}</button
                  >
                  <button
                    type="button"
                    style="background-color:#945FAF; width:8rem;padding-block:0.5rem;color:white;border-radius:0.5rem"
                    on:click={DeleteModal(model)}
                    >{$_('workspace.oui')}</button
                  >
                 
                  
                </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- {/if} -->
<style>
  .modal {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* allow click-through to backdrop */
    pointer-events: none;
  }

  .contents {
    min-width: 240px;
    border-radius: 6px;
    padding: 16px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: auto;
  }
</style>
