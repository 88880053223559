import Router from './Router.svelte';
import { getAsset } from './utils/prepareAssets.js';

getAsset('pdfjsLib');
const app = new Router({
  target: document.body,
});


export default app;
