<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { pannable } from "../../utils/pannable.js";
  import { Fonts } from "../../utils/prepareAssets.js";
  import { tapout } from "../../utils/tapout.js";
  import lodash from "lodash";
  import Toolbar from "../../components/Toolbar.svelte";
  import { _ } from "../../services/i18n.js";
  import CheckBox from "../../components/CheckBox/CheckBox.svelte";
  import DatePicker from "../../components/DatePicker/DatePicker.svelte";
  import SelectOptions from "../../components/SelectOptions/SelectOptions.svelte";
  export let width;
  export let height;
  export let x;
  export let id;
  export const text = "text";
  export let y;
  export let pageScale = 1;
  export let lineHeight;
  export let size;
  export let fontFamily;
  export let editMode;
  export let charactersNumber;
  export let typeSelected;
  export let visibility;
  export let relation;
  export let color;
  export let details;
  export let position;
  export let selectedPageIndex;
  export let pIndex;
  export let pdfpageHeight;
  export let lastModification;
  export let scroll;
  export let options = [];
  export let type;
  let checktypes = ["X", "✓"];
  let dateFormats = ["YYYY-MM-DD", "DD-MM-YYYY", "YYYY/MM/DD", "DD/MM/YYYY"];
  const Families = Object.keys(Fonts);
  let variants = Fonts[fontFamily] ? Fonts[fontFamily].variants : ["regular"];
  export let _currentVariant = variants[0];
  const Types = [
    "Text",
    "Check",
    "Email",
    "Numero",
    "BIC",
    "IBAN",
    "Image",
    "HTML",
    "Date",
  ];
  let TextFormats = [
    "Basique",
    "Adaptation Taille Police",
    "Retour a la ligne automatique",
  ];
  export let TextFormat = "Basique";
  let langues = ["latin", "arabic"];
  export let langue = "latin";
  let _fontFamily = fontFamily;
  const numFormats = ["Default", "Mobile"];
  let numFormat = "Default";
  const dispatch = createEventDispatcher();
  let startX;
  let startY;
  let canvas;
  let operation = "";
  let directions = [];
  let _size = size;
  let _lineHeight = lineHeight;
  let editable;
  let dx = 0;
  let dy = 0;
  let dw = 0;
  let dh = 0;
  let highlight = false;
  let selected = false;
  let copyimage;
  let hex = "#000000";
  let selectedObject;
  let scrollPosition = 0;
  var typingTimer; //timer identifier
  var doneTypingInterval = 1000;
  let validInput = true;
  export let checkFormat = "X";
  export let dateFormat = "DD-MM-YYYY";
  export let line;
  async function render() {
    // editable.innerHTML = "";
    /*  dispatch("update", {
      width: width * pageScale,
      height: height * pageScale,
      lines: extractLines(),
    }); */
  }

  function handlePanMove(event) {
    /* onChangePage(yy); */

    const _dx = (event.detail.x - startX) / pageScale;
    const _dy = (event.detail.y - startY) / pageScale;

    if (operation === "move") {
      dx = _dx;
      dy = _dy;
    } else if (operation === "scale") {
      if (directions.includes("left")) {
        dx = _dx;
        dw = -_dx;
      }

      if (directions.includes("top")) {
        dy = _dy;
        dh = -_dy;
      }
      if (directions.includes("right")) {
        dw = _dx;
      }
      if (directions.includes("bottom")) {
        dh = _dy;
      }
    }
  }
  function sanitize() {
    let weirdNode;
    while (
      (weirdNode = Array.from(editable.childNodes).find(
        (node) => !["#text", "BR"].includes(node.nodeName)
      ))
    ) {
      editable.removeChild(weirdNode);
    }
  }

  function decToHex(value) {
    if (value > 255) {
      return "FF";
    } else if (value < 0) {
      return "00";
    } else {
      return value.toString(16).padStart(2, "0").toUpperCase();
    }
  }
  function rgbToHex(r, g, b) {
    return "#" + decToHex(r) + decToHex(g) + decToHex(b);
  }

  function handlePanEnd(event) {
    operation = "";
  }

  export function onFocusTool() {
    operation = "tool";
  }

  function isValidIBan(iban) {
    var regex = new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,27}/);
    let validIban = regex.test(iban);

    return validIban;
  }

  function isValidBic(bic) {
    var regex = new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/);
    let validBic = regex.test(bic);

    return validBic;
  }
  function onKeyup() {
    if (["BIC", "IBAN"].includes(type)) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(doneTyping, doneTypingInterval);
    }
  }

  function doneTyping() {
    let value = extractLines();
    switch (type) {
      case "BIC":
        let BicValidation = isValidBic(value);
        if (!BicValidation) {
          alert("PLEASE VALIDATE YOUR BIC");
        }
        break;
      case "IBAN":
        let IbanValidation = isValidIBan(value);
        if (!IbanValidation) {
          alert("PLEASE VALIDATE YOUR IBAN");
        }
        break;
      default:
        break;
    }
  }

  function onKeydown(e) {
    if (["BIC", "IBAN"].includes(type)) {
      clearTimeout(typingTimer);
    }
    if (editMode == "EDIT") {
      const childNodes = Array.from(editable.childNodes);
      if (e.keyCode === 13) {
        // prevent default adding div behavior
        e.preventDefault();
        const selection = window.getSelection();
        const focusNode = selection.focusNode;
        const focusOffset = selection.focusOffset;
        // the caret is at an empty line
        if (focusNode === editable) {
          editable.insertBefore(
            document.createElement("br"),
            childNodes[focusOffset]
          );
        } else if (focusNode instanceof HTMLBRElement) {
          editable.insertBefore(document.createElement("br"), focusNode);
        }
        // the caret is at a text line but not end
        else if (focusNode.textContent.length !== focusOffset) {
          document.execCommand("insertHTML", false, "<br>");
          // the carat is at the end of a text line
        } else {
          let br = focusNode.nextSibling;
          if (br) {
            editable.insertBefore(document.createElement("br"), br);
          } else {
            br = editable.appendChild(document.createElement("br"));
            br = editable.appendChild(document.createElement("br"));
          }
          // set selection to new line
          selection.collapse(br, 0);
        }
      }

      dispatch("update", {
        lines: extractLines(),
        key: line,
      });
    }
  }

  function extractLines() {
    const nodes = editable.childNodes;
    let lineText = "";
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      lineText += node.textContent;
    }
    return lineText;
  }

  function onBlur() {
    operation = "";
    highlight = false;
    selected = false;
  }
  export function onFocus() {
    if (editMode == "EDIT") {
      highlight = true;
      operation = "edit";
    }
  }

  onMount(render);
</script>

{#if type == "Check"}
  <div
    class="absolute left-0 top-0 select-none"
    style="width: {width + dw}px; height: {height +
      dh}px; transform: translate({x}px,
  {y}px);"
    use:tapout
    on:tapout={onBlur}
  >
    <CheckBox
      height={height + dh}
      name={line}
      on:check={(data) => {
        dispatch("update", {
          lines: data.detail.state,
          key: line,
        });
      }}
    />
  </div>
{:else if type == "Date"}
  <div
    class="absolute left-0 top-0 select-none"
    style="width: {width + dw}px; height: {height +
      dh}px; transform: translate({x + dx}px,
  {y - 10}px);"
    use:tapout
    on:tapout={onBlur}
  >
    <DatePicker
      width="{width}px"
      height="{height}px"
      on:pick={(data) => {
        dispatch("update", {
          lines: data.detail.date,
          key: line,
        });
      }}
    />
  </div>
{:else if TextFormat == "Selection"}
  <div
    class="absolute left-0 top-0 select-none"
    style="width: {width + dw}px; height: {height +
      dh}px; transform: translate({x + dx}px,
  {y - 10}px);"
  >
    <SelectOptions
      {options}
      width="{width}px"
      height="{height}px"
      on:select={(e) => {
        dispatch("update", {
          lines: e.detail.value,
          key: line,
        });
      }}
    />
  </div>
{:else}
  <div
    class="editing absolute left-0 top-0 select-none"
    style="width: {width + dw}px; height: {height +
      dh}px; transform: translate({x + dx}px,
  {y + dy}px);"
    use:tapout
    on:tapout={onBlur}
  >
    <div
      use:pannable
      on:panend={handlePanEnd}
      class=" w-full h-full cursor-grab"
      class:cursor-grabbing={operation === "tool"}
      class:operation
      on:click={() => {
        editable.focus();
        selected = true;
        operation = "tool";
        dispatch("mode", "EDIT");
      }}
    >

      <div
        bind:this={editable}
        on:keydown={onKeydown}
        on:keyup={onKeyup}
        on:focus={onFocus}
        on:blur={onBlur}
        contenteditable={editMode == "EDIT" ? "true" : "false"}
        class="outline-none"
        spellcheck="false"
        style="font-size: {_size}px; font-family: '{_fontFamily}', serif;
    white-space:nowrap; overflow: hidden; white-space: nowrap;
line-height: {_lineHeight}; {editMode == 'MOVE'
          ? 'pointer-events:none;-webkit-user-select: text;  user-select: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;'
          : ''}"
      />
    </div>
  </div>
{/if}

<style>
  .operation {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .editing {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .resize-border {
    @apply absolute border-dashed border-gray-600;
  }
  .ruler-x {
    height: var(--ruler2-h);
    inset-block-start: 0;
    inset-inline-start: calc(var(--ruler-unit) * var(--ruler2-space));
    opacity: var(--ruler-x);
    width: 600px;
  }
  .ruler-y {
    flex-direction: column;
    height: 820px;
    inset-block-start: calc(var(--ruler-unit) * var(--ruler2-space));
    inset-inline-start: 0;
    opacity: var(--ruler-y);
    width: var(--ruler2-h);
  }
  .ruler_container {
    --ruler-num-c: #888;
    --ruler-num-fz: 10px;
    --ruler-num-pi: 0.75ch;
    --ruler-unit: 1px;
    --ruler-x: 1;
    --ruler-y: 1;

    --ruler1-bdw: 1px;
    --ruler1-c: #bbb;
    --ruler1-h: 8px;
    --ruler1-space: 5;

    --ruler2-bdw: 1px;
    --ruler2-c: #bbb;
    --ruler2-h: 20px;
    --ruler2-space: 50;

    background-attachment: fixed;
    background-image: linear-gradient(
        90deg,
        var(--ruler1-c) 0 var(--ruler1-bdw),
        transparent 0
      ),
      linear-gradient(90deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler1-c) 0 var(--ruler1-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0);
    background-position: 0 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: calc(
          var(--ruler-unit) * var(--ruler1-space) * var(--ruler-x)
        )
        var(--ruler1-h),
      calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-x))
        var(--ruler2-h),
      var(--ruler1-h)
        calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-y)),
      var(--ruler2-h)
        calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-y));
  }
  .dots {
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
    border-radius: 9999px;
  }
  .sidebar {
    -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    background-color: #624771; /* Background color of the scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #945faf; /* Color of the thumb */
  }
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  input[type="color"] {
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    width: 50%;
    height: 30px;
    border-radius: 5px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 5px;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
  }
</style>
