<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { pannable } from "../../utils/pannable.js";
  import { create_history } from "../../utils/history";
  import { delete_history } from "../../utils/history";
  import { Fonts } from "../../utils/prepareAssets.js";
  import { tapout } from "../../utils/tapout.js";
  import Toolbar from "../../components/Toolbar.svelte";
  import { _ } from "../../services/i18n"
  import { HsvPicker } from "svelte-color-picker";
  export let width;
  export let height;
  export let x;
  export const text = "text";
  export let y;
  export let pageScale = 1;
  export let lineHeight;
  export let size;
  export let fontFamily;
  export let editMode;
  export let charactersNumber;
  export let checkFormat = "X";
  export let typeSelected;
  export let line;
  export let visibility;
  let dateFormats = ["YYYY-MM-DD","DD-MM-YYYY","YYYY/MM/DD", "DD/MM/YYYY"]
  let TextFormats= ["Basique","Adaptation Taille Police", "Retour a la ligne automatique"]
  export let TextFormat = "Basique"
  let langues= ["latin","arabic"]
  let alignments=["left","center","right"]
  export let alignment="left"
  export let langue = "latin"

  export let dateFormat = "DD-MM-YYYY";
  let variants = Fonts[fontFamily].variants;
  export let _currentVariant = variants[0];
  /* export let relation; */
  export let color = {r:0,g:0,b:0};
  let selected = false;
  const Families = Object.keys(Fonts);
  const Types = ["Text", "Check", "Email", "Numero", "BIC", "IBAN","HTML","Date"];
  let _fontFamily = fontFamily;
  const numFormats = ["Default", "Mobile"];
  let numFormat = "Default";
  const dispatch = createEventDispatcher();
  let startX;
  let startY;
  let canvas;
  let operation = "";
  let directions = [];
  let _size = size;
  let _lineHeight = lineHeight;
  let editable;
  let dx = 0;
  let dy = 0;
  let dw = 0;
  let dh = 0;
  let highlight = false;
  let checktypes = ["X", "✓"];

  async function render() {
    // use canvas to prevent img tag's auto resize
    editable.innerHTML = $_('workspace.champText');
    // editable.focus();

    dispatch("update", {
      width: width * pageScale,
      height: height * pageScale,
      lines: extractLines(),
    });
  }
  function handlePanMove(event) {
    const _dx = (event.detail.x - startX) / pageScale;
    const _dy = (event.detail.y - startY) / pageScale;
    if (operation === "move") {
      dx = _dx;
      dy = _dy;
    } else if (operation === "scale") {
      if (directions.includes("left")) {
        dx = _dx;
        dw = -_dx;
      }
      if (directions.includes("top")) {
        dy = _dy;
        dh = -_dy;
      }
      if (directions.includes("right")) {
        dw = _dx;
      }
      if (directions.includes("bottom")) {
        dh = _dy;
      }
    }
  }
  function colorCallback(rgba) {
    dispatch("update", {
      color: rgba,
    });
  }

  function decToHex(value) {
    if (value > 255) {
      return "FF";
    } else if (value < 0) {
      return "00";
    } else {
      return value.toString(16).padStart(2, "0").toUpperCase();
    }
  }
  function rgbToHex(r, g, b) {
    return "#" + decToHex(r) + decToHex(g) + decToHex(b);
  }
  function sanitize() {
    let weirdNode;
    while (
      (weirdNode = Array.from(editable.childNodes).find(
        (node) => !["#text", "BR"].includes(node.nodeName)
      ))
    ) {
      editable.removeChild(weirdNode);
    }
  }

  function handlePanEnd(event) {
    if (dx === 0 && dy === 0) {
      return editable.focus();
    }
    if (operation === "move") {
      dispatch("update", {
        x: x + dx,
        y: y + dy,
      });
      dx = 0;
      dy = 0;
    } else if (operation === "scale") {
      dispatch("update", {
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      directions = [];
    }
    operation = "";
    if (editMode == "EDIT") dispatch("mode", "MOVE");
    window.removeEventListener("keydown", onKeyDownTool, false);
  }

  function onFocusTool() {
    operation = "tool";
  }
  function handlePanStart(event) {
    selected = true;
    if (editMode == "MOVE" && selected) {
      window.addEventListener("keydown", onKeyDownTool, false);
    }
    startX = event.detail.x;
    startY = event.detail.y;
    if (event.detail.target === event.currentTarget) {
      return (operation = "move");
    }
    operation = "scale";
    directions = event.detail.target.dataset.direction.split("-");
  }
  function onDelete() {
    window.removeEventListener("keydown", onKeyDownTool, false);
    dispatch("delete");
  }
  function onChangeFont() {
    variants = Fonts[_fontFamily].variants;
    dispatch("update", {
      fontFamily: _fontFamily,
      fontUrl: Fonts[_fontFamily].files[_currentVariant],
      variant: _currentVariant,
    });
  }
  function onChangeVariant() {
    dispatch("update", {
      fontUrl: Fonts[_fontFamily].files[_currentVariant],
      variant: _currentVariant,
    });
  }
  function onChangeSize() {
    dispatch("update", {
      size: _size,
    });
  }
  function onChangeLineHeight() {
    dispatch("update", {
      name: _lineHeight,
    });
  }
  function onChangeType() {
    if (typeSelected === "Text") {
      dispatch("update", {
        fieldType: typeSelected,
        numFormat: numFormat,
        TextFormat: TextFormat,
        langue: langue,
        alignment:alignment,
        FormatCode: 0,
      });
    }else{
      dispatch("update", {
        fieldType: typeSelected,
        numFormat: numFormat,
      });
    }
  }
  function onChangeNumFormat() {
    dispatch("update", {
      numFormat: numFormat,
    });
  }

  function onChangeCharactersNumber() {
    dispatch("update", {
      charactersNumber: charactersNumber,
    });
  }

  function updateText(e) {
    editable.innerHTML = e.target.value;
    dispatch("update", {
      lines: extractLines(),
    });
  }
  function updateRelation(e) {
    dispatch("update", {
      relation: e.target.value,
    });
  }
  function onKeydown(e) {
    const childNodes = Array.from(editable.childNodes);
    if (e.keyCode === 13) {
      // prevent default adding div behavior
      e.preventDefault();
      const selection = window.getSelection();
      const focusNode = selection.focusNode;
      const focusOffset = selection.focusOffset;
      // the caret is at an empty line
      if (focusNode === editable) {
        editable.insertBefore(
          document.createElement("br"),
          childNodes[focusOffset]
        );
      } else if (focusNode instanceof HTMLBRElement) {
        editable.insertBefore(document.createElement("br"), focusNode);
      }
      // the caret is at a text line but not end
      else if (focusNode.textContent.length !== focusOffset) {
        document.execCommand("insertHTML", false, "<br>");
        // the carat is at the end of a text line
      } else {
        let br = focusNode.nextSibling;
        if (br) {
          editable.insertBefore(document.createElement("br"), br);
        } else {
          br = editable.appendChild(document.createElement("br"));
          br = editable.appendChild(document.createElement("br"));
        }
        // set selection to new line
        selection.collapse(br, 0);
      }
    }
    dispatch("update", {
      lines: extractLines(),
    });
  }

  function extractLines() {
    const nodes = editable.childNodes;
    let lineText = "";
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      lineText += node.textContent;
    }
    return lineText;
  }

  function onBlur() {
    if (editMode == "EDIT" && highlight) {
      if (operation !== "edit" || operation === "tool") return;
      editable.blur();
      sanitize();

      dispatch("update", {
        lines: extractLines(),
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      operation = "";
      highlight = false;
    } else if (editMode == "MOVE" && selected) {
      editable.blur();
      sanitize();

      dispatch("update", {
        lines: extractLines(),
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      operation = "";
      highlight = false;
      selected = false;
    }
    window.removeEventListener("keydown", onKeyDownTool, false);
    window.onscroll = function () {};
  }
  function onFocus() {
    if (editMode == "EDIT") {
      highlight = true;
      operation = "edit";
    }
  }
  function onFocusOut() {
    operation = "";
  }
  function onCopyTool(ev) {
    // function to check the detection
    ev = ev || window.event; // Event object 'ev'
    var key = ev.which || ev.keyCode; // Detecting keyCode

    // Detecting Ctrl
    var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;

    // If key pressed is V and if ctrl is true.
    if (key == 86 && ctrl) {
      // print in console.
      let timestampId = Date.now();
      dispatch("copy", {
        id: timestampId,
        type: "image",
        width: width / pageScale,
        height: height / pageScale,
        x: 0,
        y: 0,
        size: _size,
        lineHeight: _lineHeight,
        fieldType: typeSelected,
        charactersNumber: charactersNumber,
        line: line,
      });
    }
  }

  function onKeyDownTool(e) {
    onCopyTool(e);
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
    switch (e.key) {
      case "ArrowDown":
        dispatch("update", {
          y: y + 1,
        });
        break;
      case "ArrowUp":
        dispatch("update", {
          y: y - 1,
        });
        break;
      case "ArrowRight":
        dispatch("update", {
          x: x + 1,
        });
        break;
      case "ArrowLeft":
        dispatch("update", {
          x: x - 1,
        });
        break;
      default:
        break;
    }
  }
  async function onBlurTool() {
    if (operation !== "tool" || operation === "edit"|| selected === false) return;
    dispatch("update", {
      lines: extractLines(),
      lineHeight: _lineHeight,
      size: _size,
      fontFamily: _fontFamily,
    });
    operation = "";
    dispatch("mode", "MOVE");
  }

  function onChangeVisibility() {
    dispatch("update", {
      visibility: visibility,
    });
  }

  onMount(render);
</script>

{#if operation && editMode == "MOVE"}
  <div
    class="absolute select-none"
    style="width:{280 * 2.13}px;transform: translate(0px,
    {y + dy + size - lineHeight * 2}px);"
  >
    <hr style="border-top: 0.1px dashed #888;" />
  </div>

  <div class="ruler_container">
    <ul class="ruler-x">
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
    <ul class="ruler-y">
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
  </div>
{/if}
{#if operation && editMode == "EDIT"}
  <Toolbar>
    <div
      use:tapout
      on:tapout={onBlurTool}
      on:mousedown={onFocusTool}
      on:touchstart={onFocusTool}
    >
      <div
        class="sidebar fixed top-0 mt-12 bottom-0 lg:left-0 p-2 w-[400px] overflow-y-auto text-center bg-gray-900"
      >
        <div>
          <h2 class="text-white">Id :</h2>
        </div>
        <div class="flex justify-center items-center m-5">
          <input
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="text"
            type="text"
            bind:value={line}
            on:change={updateText}
          />
        </div>
        <div class="mr-2 flex-col items-center">
          <div style="margin: 5px;">
            <p class="text-white">{$_('workspace.hauteurLigne')}</p>
            <input
              type="number"
              min="1"
              max="10"
              step="0.1"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              bind:value={_lineHeight}
              on:change={onChangeLineHeight}
            />
          </div>
        </div>
        <div class="mr-2 flex-col items-center">
          <div style="margin: 5px;">
            <p class="text-white">{$_('workspace.tailleText')}</p>
            <input
              type="number"
              min="1"
              max="120"
              step="1"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              bind:value={_size}
              on:change={onChangeSize}
            />
          </div>
        </div>

        {#if typeSelected == "Text"}
          <div class="mr-2 flex-col items-center">
            <div style="margin: 5px;">
              <p class="text-white">{$_('workspace.famillePolice')}</p>
              <div class="relative w-60 md:w-60">
                <select
                  bind:value={_fontFamily}
                  on:change={onChangeFont}
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  {#each Families as family}
                    <option value={family}>{family}</option>
                  {/each}
                </select>
              </div>
            </div>
          </div>
          <div class="mr-2 flex-col items-center">
            <div style="margin: 5px;">
              <p class="text-white">{$_('workspace.typePolice')}</p>
              <div class="relative w-60 md:w-60">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  bind:value={_currentVariant}
                  on:change={onChangeVariant}
                >
                  {#each variants as variant}
                    <option value={variant}> {variant} </option>
                  {/each}
                </select>
              </div>
            </div>
          </div>
        {/if}
        <div class="mr-2 flex-col items-center">
          <div style="margin: 5px;">
            <p class="text-white">{$_('workspace.typeContenu')}</p>
            <div class="relative w-60 md:w-60">
              <select
                bind:value={typeSelected}
                on:change={onChangeType}
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                {#each Types as type}
                  <option value={type}>{type}</option>
                {/each}
              </select>
            </div>
          </div>
        </div>
        {#if typeSelected == "Text"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Text Alignment</p>
                <div style="display:flex;flex-direction:row;">
                <svg
                class="z-10"
                width="48"
                height="46"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                <path
                  d="M8 12.89V11.07C8 9.92 8.93 9 10.07 9H24.59C25.74 9 26.66 9.93 26.66 11.07V12.89M17.33 26.44V9.83M13.39 26.44H21.27"
                  stroke="#945FAF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  bind:value={alignment}
                  on:change={() => {
                    
                        dispatch("update", {
                          alignment: alignment,
                        });
                       
                  }}
                >
                  
                {#each alignments as lg}
                    <option value={lg}>{lg}</option>
                  {/each}
                
                </select>
              </div>
            </div>
            </div>
          {/if}
        {#if typeSelected == "Text"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Type de caractere</p>
                <svg
                class="z-10"
                width="48"
                height="46"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                <path
                  d="M8 12.89V11.07C8 9.92 8.93 9 10.07 9H24.59C25.74 9 26.66 9.93 26.66 11.07V12.89M17.33 26.44V9.83M13.39 26.44H21.27"
                  stroke="#945FAF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  bind:value={langue}
                  on:change={() => {
                    
                        dispatch("update", {
                          langue: langue,
                        });
                       
                  }}
                >
                  
                {#each langues as lg}
                    <option value={lg}>{lg}</option>
                  {/each}
                
                </select>
              </div>
            </div>
          {/if}
        {#if typeSelected == "Text"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Option</p>
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  bind:value={TextFormat}
                  on:change={() => {
                    switch (TextFormat) {
                      case "Basique":
                        dispatch("update", {
                          TextFormat: TextFormat,
                          FormatCode: 0,
                        });
                        break;
                      case "Adaptation Taille Police":
                        dispatch("update", {
                          TextFormat: TextFormat,
                          FormatCode: 1,
                        });
                        break;
                      case "Retour a la ligne automatique":
                        dispatch("update", {
                          TextFormat: TextFormat,
                          FormatCode: 2,
                        });
                        break;
                    }
                  }}
                >
                  {#each TextFormats as format}
                    <option value={format}>{format}</option>
                  {/each}
                </select>
              </div>
            </div>
          {/if}
        {#if typeSelected == "Date"}
        <div class="mr-2 flex-col items-center">
          <div style="margin: 5px;">
            <p class="text-white">Format</p>
            <select
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
              bind:value={dateFormat}
              on:change={() =>
                dispatch("update", {
                  dateFormat: dateFormat,
                })}
            >
              {#each dateFormats as format}
                <option value={format}>{format}</option>
              {/each}
            </select>
          </div>
        </div>
      {/if}
        {#if typeSelected == "Numero"}
          <div class="mr-2 flex-col items-center">
            <div style="margin: 5px;">
              <p class="text-white">Model du Numero</p>
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                bind:value={numFormat}
                on:change={onChangeNumFormat}
              >
                {#each numFormats as format}
                  <option value={format}>{format}</option>
                {/each}
              </select>
            </div>
          </div>
        {/if}

        {#if typeSelected == "Check"}
          <div class="mr-2 flex-col items-center">
            <div style="margin: 5px;">
              <p class="text-white">Type</p>
              <select
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                bind:value={checkFormat}
                on:change={() =>
                  dispatch("update", {
                    checkFormat: checkFormat,
                  })}
              >
                {#each checktypes as type}
                  <option value={type}>{type}</option>
                {/each}
              </select>
            </div>
          </div>
        {/if}
        <div class="mr-2 flex-col items-center">
          <div style="margin: 5px;">
            <p class="text-white">{$_('workspace.nombreCaracteres')}</p>
            <input
              type="number"
              min="0"
              step="1"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              bind:value={charactersNumber}
              on:change={onChangeCharactersNumber}
            />
          </div>
        </div>
        <div class="mr-2 flex-col items-center">
          <div style="margin: 5px;">
            <p class="text-white">{$_('workspace.Visibilité')}</p>
            <select
              bind:value={visibility}
              on:change={onChangeVisibility}
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value="Oui">{$_('workspace.oui')}</option>
              <option value="Non">{$_('workspace.non')}</option>
            </select>
          </div>
        </div>

        <div
          style="display: flex;flex-direction:column;justify-content:center;align-items:center;"
        >
          <p class="text-white">Couleur de text</p>
          <input
            on:change={(e) => {
              const red = parseInt(e.target.value.substring(1, 3), 16);
              const green = parseInt(e.target.value.substring(3, 5), 16);
              const blue = parseInt(e.target.value.substring(5, 7), 16);
              colorCallback({ r: red, g: green, b: blue });
            }}
            type="color"
            id="favcolor"
            name="favcolor"
            value={rgbToHex(color.r, color.g, color.b)}
          />
        </div>
      </div>
    </div></Toolbar
  >
{/if}

<div
  class="absolute left-0 top-0 select-none"
  style="width: {width + dw}px; height: {height +
    dh}px; transform: translate({x + dx}px,
  {y + dy}px);"
  use:tapout
  on:tapout={onBlur}
>
  <div
    use:pannable
    on:panstart={handlePanStart}
    on:panmove={handlePanMove}
    on:panend={handlePanEnd}
    on:dblclick={() => {selected = true;
      operation = "tool";
      dispatch("mode", "EDIT");}}
    class="absolute w-full h-full {editMode === 'MOVE'
      ? 'cursor-move'
      : 'cursor-crosshair'}"
    class:cursor-grabbing={operation === "move"}
    class:operation
  >
    <div
      data-direction="left"
      class="resize-border h-full w-1 left-0 top-0 border-l cursor-ew-resize"
    />
    <div
      data-direction="top"
      class="resize-border w-full h-1 left-0 top-0 border-t cursor-ns-resize"
    />
    <div
      data-direction="bottom"
      class="resize-border w-full h-1 left-0 bottom-0 border-b cursor-ns-resize"
    />
    <div
      data-direction="right"
      class="resize-border h-full w-1 right-0 top-0 border-r cursor-ew-resize"
    />
    {#if operation}
      <div
        data-direction="left-top"
        class="dots left-0 top-0 cursor-nwse-resize transform
   -translate-x-1/2 -translate-y-1/2 "
      />
      <div
        data-direction="right-top"
        class="dots right-0 top-0 cursor-nesw-resize transform
   translate-x-1/2 -translate-y-1/2 "
      />
      <div
        data-direction="left-bottom"
        class="dots left-0 bottom-0 cursor-nesw-resize transform
   -translate-x-1/2 translate-y-1/2 "
      />
      <div
        data-direction="right-bottom"
        class="dots right-0 bottom-0 cursor-nwse-resize transform
   translate-x-1/2 translate-y-1/2 "
      />
      <div
        on:click={onDelete}
        class="absolute left-0 top-0 right-0 w-12 h-12 m-auto rounded-full bg-white
   cursor-pointer transform -translate-y-1/2 md:scale-25"
      >
        <img class="w-full h-full" src="/delete.svg" alt="delete object" />
      </div>
    {/if}

    <div
      bind:this={editable}
      on:keydown={onKeydown}
      on:focus={onFocus}
      contenteditable={editMode == "EDIT" ? "true" : "false"}
      spellcheck="false"
      class="outline-none"
      style="font-size: {_size}px; font-family: '{_fontFamily}', serif;
      color: rgb({color.r}, {color.g}, {color.b});
        white-space:nowrap; overflow: hidden; white-space: nowrap;
    line-height: {_lineHeight}; {editMode == 'MOVE'
        ? 'pointer-events:none;-webkit-user-select: text;  user-select: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;'
        : ''} "
    />
  </div>
</div>

<style>
  .operation {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .resize-border {
    @apply absolute border-dashed border-gray-600;
  }

  .ruler-x {
    height: var(--ruler2-h);
    inset-block-start: 0;
    inset-inline-start: calc(var(--ruler-unit) * var(--ruler2-space));
    opacity: var(--ruler-x);
    width: 600px;
  }
  .ruler-y {
    flex-direction: column;
    height: 820px;
    inset-block-start: calc(var(--ruler-unit) * var(--ruler2-space));
    inset-inline-start: 0;
    opacity: var(--ruler-y);
    width: var(--ruler2-h);
  }
  .ruler_container {
    --ruler-num-c: #888;
    --ruler-num-fz: 10px;
    --ruler-num-pi: 0.75ch;
    --ruler-unit: 1px;
    --ruler-x: 1;
    --ruler-y: 1;

    --ruler1-bdw: 1px;
    --ruler1-c: #bbb;
    --ruler1-h: 8px;
    --ruler1-space: 5;

    --ruler2-bdw: 1px;
    --ruler2-c: #bbb;
    --ruler2-h: 20px;
    --ruler2-space: 50;

    background-attachment: fixed;
    background-image: linear-gradient(
        90deg,
        var(--ruler1-c) 0 var(--ruler1-bdw),
        transparent 0
      ),
      linear-gradient(90deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler1-c) 0 var(--ruler1-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0);
    background-position: 0 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: calc(
          var(--ruler-unit) * var(--ruler1-space) * var(--ruler-x)
        )
        var(--ruler1-h),
      calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-x))
        var(--ruler2-h),
      var(--ruler1-h)
        calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-y)),
      var(--ruler2-h)
        calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-y));
  }
  .dots {
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
    border-radius: 9999px;
  }
  .sidebar {
    -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  input[type="color"] {
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    width: 50%;
    height: 30px;
    border-radius: 5px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 5px;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
  }
</style>
