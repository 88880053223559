import {
  dictionary,
  locale,
  _
} from 'svelte-i18n';
function setupI18n({
  withLocale: _locale
} = {
  withLocale: navigator.language|| navigator.userLanguage
}) {
  dictionary.set({
    fr: {
      "home": {
        "déconnecter": "Déconnecter " 
      },
      "workspace": {
        "workspaceCreate": " Créer un nouveau projet",
        "date": "Date de création",
        "supprimer": "Supprimer",
        "modifier" : "Modifier",
        "createProject":"Création d’un espace de travail ( nouveau projet )",
        "nomProject":"Nom du projet",
        "imageProject":"Chargez une image à votre projet",
        "annuler":"Annuler",
        "confirmer":"Confirmer",
        "accéder":"Accéder",
        "supprimerProjet":"Supprimer un projet",
        "textSupprimerProjet":"Voulez-vous supprimer définitivement ce projet? Cette suppression ne peut pas être annulée.",
        "oui":"Oui",
        "non":"Non",
        "retour":"Retour",
        "projet":"Projet",
        "createModel":"Créer un nouveau modèle",
        "Fichier_introuvable":"Fichier introuvable",
        "ImportModel":"Importer un modèle",
        "ImportPdf":"Importer un PDF",
        "generateLivePrint":"Générer un URL",
        "tailleFile":"Accepte seulement les fichiers de type PDF Max size file 10MB",
        "glisserFile":"Glissez - Déposez ici",
        "Outils":"Outils",
        "nomModele":"Nom du Modéle",
        "enregistrer":"Enregistrer",
        "exporter":"Exporter",
        "numPage":"Page selectionnée",
        "champText":"Veuillez identifier ce champ de texte",
        "hauteurLigne":"Hauteur de la ligne",
        "typeContenu":"Type de contenu de champ",
        "tailleText":"Taille du texte",
        "famillePolice":"Famille de polices",
        "typePolice":"Type de police",
        "nombreCaracteres":"Nombre de caractères autorisés",
        "Visibilité":"Visibilité",
        "filigrane":"filigrane",
        "couleurText":"Couleur du texte",
        "alertNameModel":"Il faut indiquer le nom du model",
        "verified":"Merci de vérifier",
        "travailEnregistré":"Modèle modifié avec succès",
        "alertDelete":"Projet Supprimé avec succès",
        "Échec de suppression du projet":"Échec de suppression du projet",
        "alertDeleteModel":"Modèle Supprimé avec succès",
        "copiedSuccessfully":"Modèle copié avec succès",
        "copié":"Copié",
        "supprimerModéle":" Supprimer un modèle",
        "textSupprimerModéle":"Voulez-vous supprimer définitivement ce modèle? Cette suppression ne peut pas être annulée.",
        "Copie des champs du fichier modèle":"Copie des champs du fichier modèle",
        "Créer des sections":"Créer des sections",
        "Creation de section":"Création de section",
        "Niveau":"Niveau",
        "Merci d'identifier les champs":"Merci d'identifier les champs.",
        "Veuillez introduire le nom de votre projet":"Veuillez introduire le nom de votre projet !",
        "nom":"Nom",
        "Nom est obligatoire":"Nom est obligatoire",
        "Vous devez charger un fichier PDF":"Vous devez charger un fichier PDF",
        "Vous devez choisir un pdf de meme nombre de pages":"Vous devez choisir un pdf de meme nombre de pages",
        "Id Modèle":"Id Modèle",
        "Verifier l'intégrité  de votre fichier":"Verifier l'intégrité  de votre fichier!",
        "Failed to add pdf":"Failed to add pdf!",
        "il faut choisir un pdf exporté":"Il faut choisir un pdf exporté !",
        "Vous devez charger un fichier PDF":"Vous devez charger un fichier PDF",
        "Done":"Terminé",
        "Dashboard":"Tableau de bord",
        "Exported File":"Fichier exporté",
        "Projet crée avec succés":"Projet crée avec succès",
        "notFound":"Désolé pour cela ! Veuillez visiter notre page d'accueil pour accéder à l'endroit où vous devez aller.",
        "ERROR 404":"ERREUR 404",
        "Erreur d'enregistrement des champs":"Erreur d'enregistrement des champs...",
        "upload error":"Erreur de téléversement ...",
        "Failed to add pdf":"Échec d'ajout du PDF !",
        "Il faut importer un pdf":"Il faut importer un pdf",
        "Glisser votre pdf ici":"Glisser votre pdf ici",
        "Something went wrong":"Quelque chose s'est mal passé !",
        "Erreur de modification":"Échec de modification du modèle",
        "Modéle du numéro téléphone":"Format de numéro de téléphone",
        "Il faut selectionnez un fichier PDF":"Il faut selectionnez un fichier PDF",
        "Merci de verifier le type de votre fichier":"Merci de verifier le type de votre fichier !",
        "Il faut selectionnez un fichier PDF exporté":"Il faut selectionnez un fichier PDF exporté !",
        "Model copied successfully":"Modèle copié avec succès",
        "Hi Welcome To PDF STUDIO API":"Salut, bienvenue à PDF Studio API.",
        "Sign In":"Se connecter",
        "Please sign in to your account":"Veuillez vous connecter à votre compte.",
        "Username":"Nom d'utilisateur",
        "Password":"Mot de passe",
        "Sign in as an Admin":"Se connecter en tant qu'administrateur",
        "Sign in as a Worker":"Se connecter en tant que travailleur",
        "Enter your username":"Entrez votre nom d'utilisateur",
        "Enter your password":"Entrez votre mot de passe",
        "copier":"Copier",
        "Erreur":"Erreur !",
        "Modifier votre espace de travail":"Modifier votre espace de travail",
        "Projet modifié avec succés":"Projet modifié avec succès",
        "Wrong credentials":"Données incorrectes",
        "Permission denied":"Permission refusée",
        "Échec de création de projet":"Échec de création d'un nouveau projet",
        "Failed to create  new model":"Échec de création d'un nouveau modèle",
        "Échec de copie du modèle":"Échec de copie du modèle",
        "Échec de suppression du modèle":"Échec de suppression du modèle",
        "Échec de modification du projet":"Échec de modification du projet",
        "Failed to import model":"Échec de l'importation du modèle",
        "You have to fill at least one field":"Vous devez remplir au moins un champ",
        "Vous n'êtes pas autorisé(e) à consulter la liste des projets.":"Vous n'êtes pas autorisé(e) à consulter la liste des projets.",
        "Vous n'êtes pas autorisé(e) à consulter la liste des modèles.":"Vous n'êtes pas autorisé(e) à consulter la liste des modèles.",
        "More":"Plus"
        
        
      
     
      }
    },
    en: {
      "home": {
        "déconnecter": "Log out"
      },
      "workspace": {
        "workspaceCreate": "Create a new project",
        "Fichier_introuvable":"File not found",
        "date": "Creation date",
        "supprimer": "Delete",
        "modifier":"Update",
        "createProject":"Creation of a workspace (new project)",
        "nomProject":"Project name",
        "imageProject":"Upload an image for your project",
        "annuler":"Cancel",
        "confirmer":"Confirm",
        "accéder":"Access",
        "supprimerProjet":"Delete a project",
        "textSupprimerProjet":"Do you want to permanently delete this project? This action cannot be undone.",
        "oui":"Yes",
        "non":"No",
        "retour":"Back", 
        "projet":"Project",
        "createModel":"Create a new model",
        "ImportModel":"Import a model",
        "ImportPdf":"Import a PDF",
        "generateLivePrint":"Generate live print",
        "tailleFile":"Only accept PDF file types. Max file size 10MB",
        "You have to fill at least one field":"You have to fill at least one field",
        "glisserFile":"Drag and drop here",
        "Outils":"Tools",
        "nomModele":"Model Name",
        "enregistrer":"Save",
        "exporter":"Export",
        "numPage":"Selected page",
        "champText":"Please identify this text field",
        "hauteurLigne":"Line height",
        "typeContenu":"Field content type",
        "tailleText":"Text size",
        "famillePolice":"Font family",
        "typePolice":"Font type",
        "nombreCaracteres":"Number of allowed characters",
        "Visibilité":"Visibility",
        "filigrane":"WaterMark",
        "couleurText":"Text color",
        "alertNameModel":"You must indicate the name of the model",
        "verified":"Please check",
        "travailEnregistré":"Model modified successfully",
        "alertDeleteModel":"Model successfully deleted",
        "alertDelete":"Project successfully deleted",
        "copiedSuccessfully":"Model copied successfully",
        "copié":"Copied",
        "supprimerModéle":"Delete a model",
        "textSupprimerModéle":"Do you want to permanently delete this model? This action cannot be undone.",
        "Copie des champs du fichier modèle":"Copy fields from model file",
        "Créer des sections":"Create sections",
        "Creation de section":"Creation of section",
        "Niveau":"Level",
        "Merci d'identifier les champs":"Please identify the fields",
        "Veuillez introduire le nom de votre projet":"Please enter the name of your project.",
        "nom":"Name",
        "Nom est obligatoire":"Name is required",
        "Vous devez charger un fichier PDF":"You need to upload a PDF file",
        "Vous devez choisir un pdf de meme nombre de pages":"You need to choose a PDF with the same number of pages",
        "Id Modèle":"Model ID",
        "Verifier l'intégrité  de votre fichier":"Check the integrity of your file!",
        "Failed to add pdf":"Échec d'ajout du PDF !",
        "il faut choisir un pdf exporté":"You need to choose an exported PDF !",
        "Vous devez charger un fichier PDF":"You need to upload a PDF file.",
        "Done":"Done",
        "Dashboard":"Dashboard",
        "Exported File":"Exported File",
        "Projet crée avec succés":"Project created successfully.",
        "notFound":"Sorry about that! Please visit our home page to get where you need to go.",
        "ERROR 404":"ERROR 404",
        "Erreur d'enregistrement des champs":"Error in saving fields ...",
        "upload error":"Upload error ...",
        "Failed to add pdf":"Failed to add pdf !",
        "Il faut importer un pdf":"You need to import a PDF",
        "Glisser votre pdf ici":"Drag your PDF here",
        "Something went wrong":"Something went wrong !",
        "Erreur de modification":"Failed to modify the model",

        "Modéle du numéro téléphone":"Phone number format",
        "Il faut selectionnez un fichier PDF":"You need to select a PDF file",
        "Merci de verifier le type de votre fichier":"Please check the type of your file !",
        "Il faut selectionnez un fichier PDF exporté":"You need to select an exported PDF file",
        "Model copied successfully":"Model copied successfully",
        "Hi Welcome To PDF STUDIO API":"Hi, Welcome To PDF STUDIO API",
        "Sign In":"Sign In",
        "Please sign in to your account":"Please sign in to your account.",
        "Username":"Username",
        "Password":"Password",
        "Sign in as an Admin":"Sign in as an Admin",
        "Sign in as a Worker":"Sign in as a Worker",
        "Enter your username":"Enter your username",
        "Enter your password":"Enter your password",
        "copier":"Copy",
        "Erreur":"Error !",
        "Modifier votre espace de travail":"Edit your workspace",
        "Projet modifié avec succés":"Project successfully modified",
        "Wrong credentials":"Wrong credentials",
        "Permission denied":"Permission denied",
        "Échec de création de projet":"failed to create new project",
        "Failed to create  new model":"Failed to create  new model",
        "Échec de copie du modèle":"Failed to copy the model",
        "Échec de suppression du modèle":"Failed to delete model",
        "Failed to import model" :"Failed to import model",
        "Échec de suppression du projet":"Failed to delete the project",
        "Échec de modification du projet":"Failed to modify the project",
        "Vous n'êtes pas autorisé(e) à consulter la liste des projets.":"You are not authorized to view the list of projects.",
        "Vous n'êtes pas autorisé(e) à consulter la liste des modèles.":"You are not authorized to view the list of models.",
        "More":"More"
      }
    }

  
  });
  locale.set(_locale);
}
export {
  _,
  setupI18n
};