<script>
  import { Router, Route } from "svelte-routing";
  import NotFound from "./components/NotFound.svelte";
  import App from "./pages/App/App.svelte";
  import Home from "./pages/Home/Home.svelte";
  import Login from "./pages/Login/Login.svelte";
  import Selection from "./pages/Selection/Selection.svelte";
  import Workspaces from "./pages/Workspaces/Workspaces.svelte";
  import { setupI18n } from "./services/i18n";
  import LivePrint from "./pages/LivePrint/LivePrint.svelte";
  import Success from "./components/Success.svelte";
  import { onMount } from "svelte";
  import AppUrl from "./pages/AppUrl/AppUrl.svelte";
  export let url = "";
  setupI18n({
    withLocale:
      localStorage.getItem("language") ||
      navigator.language ||
      navigator.userLanguage,
  });
  let auth = false;
  onMount(() => {
    let ref = localStorage.getItem("refresh_token");
    let access = localStorage.getItem("access_token");
    if (ref != null && access != null) {
      auth = true;
    }
  });
</script>

<Router {url}>
  <Route path="/" component={Login} />
  <Route component={NotFound} />
  <Route path="/PDFStudioAPI">
    <LivePrint />
  </Route>
  <Route path="/Success" component={Success} />
  {#if auth}
    <Route path="/Workspaces" component={Workspaces} />
    <Route path="/HomeWorkspace" component={Home} />
    <Route path="/Workspace" component={App} />
    <Route path="/Select" component={Selection} />
    <Route path="/Studio/:id" let:params>
      <AppUrl id={params.id} />
    </Route>
  {/if}
</Router>
