<script>
  import { _ } from "../services/i18n"
</script>

<div
  class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16"
>
  <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
      <div class="relative">
          <div class="absolute">
              <div class="">
                  <h1 class="my-2 text-gray-800 font-bold text-2xl">
                      {$_('workspace.ERROR 404')}
                  </h1>
                  <p class="my-2 text-gray-800">
                      {$_('workspace.notFound')}
                  </p>
              </div>
          </div>
          <div>
              <img src="https://i.ibb.co/G9DC8S0/404-2.png" alt=""/>
          </div>
      </div>
  </div>
  <div>
      <img src="https://i.ibb.co/ck1SGFJ/Group.png" alt=""/>
  </div>
</div>
