<script>
  import axios from "axios";
  import SimpleCrypto from "simple-crypto-js";
  import { afterUpdate, onMount } from "svelte";
  import { closeModal, Modals, modals, openModal } from "svelte-modals";
  import { navigate } from "svelte-routing";
  import Swal from "sweetalert2";
  import UploadModal from "../../components/UploadModal.svelte";
  import { v4 as uuidv4 } from "uuid";
  import { readAsArrayBuffer } from "../../utils/asyncReader";
  import { getAsset } from "../../utils/prepareAssets";
  import { _ } from "../../services/i18n";
  import moment from "moment";
  import privilegeData from "../../constants/privillege";

  import SuppressionModal from "../../components/SuppressionModal.svelte";
  import { StartModelTutorial } from "../../utils/tutorial";
  let simpleCrypto = new SimpleCrypto(process.env.SVELTE_APP_SECRET_KEY);
  let privillegeTab = [];

  let models = [];

  var filtredModels = [];
  const events = [
    { name: "Birthday", date: new Date("2022-04-23") },
    { name: "Shopping", date: new Date("2022-04-17") },
    { name: "Meeting", date: new Date("2022-04-27") },
  ];

  afterUpdate(() => {
    StartModelTutorial()
  });
  onMount(() => {
    let prevs = localStorage.getItem("privilleges");
    if (prevs) {
      privillegeTab = simpleCrypto.decrypt(prevs);
    }
    let workspacecrypted = localStorage.getItem("-");
    let data = simpleCrypto.decrypt(workspacecrypted);
    var config = {
      method: "get",
      url: `${process.env.BASE_URL}/editique_pdf/get_models_of_workspace/${data.id}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then((res) => {
        models = res.data;
        filtredModels = models.sort((a, b) =>
          a.creation_date > b.creation_date && a.creation_time > b.creation_time
            ? 1
            : -1
        );
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",

          text: $_(
            "workspace.Vous n'êtes pas autorisé(e) à consulter la liste des modèles."
          ),
        });
      });
  });

  function handleOpen(model) {
    if (privillegeTab.find((q) => q.id === privilegeData["CREATE MODELS"])) {
      openModal(UploadModal, {
        model: model,
      });
    }
  }

  function handleOpenAlert(model) {
    openModal(SuppressionModal, {
      model: model,
    });
  }
  function filter(e) {
    if (e.length === 0) {
      filtredModels = models;
    } else {
      filtredModels = models.filter((model) =>
        model.modelName.toLowerCase().includes(e.toLowerCase())
      );
    }
  }
  function copy(model) {
    navigator.clipboard
      .writeText(model)
      .then(() => {
        document.getElementById(model).style =
          "display:block;position:absolute;top:-10px;left:30px;";
      })
      .catch(() => {
        document.getElementById(model).style = "display:none";
      });
    setTimeout(() => {
      document.getElementById(model).style = "display:none";
    }, 1000);
  }
</script>

<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-gray-100">
  <body class="h-full">
  ```
-->
<div class="min-h-full">
  <main>
    <div class="mx-auto py-6">
      <!-- Replace with your content -->
      <div
        style="display: flex;flex-direction:column;justify-content:center;align-items:center mx-6 "
      >
        <!-- <label for="recherche">Rechercher</label>
  <input id="recherche" type="text" on:keyup={(e)=>filter(e.target.value)} /> -->
        {#each filtredModels as model}
          <div
            class="flex flex-row lg:items-center justify-between itemContainer p-6"
            style="margin-bottom: 6vh; border-radius:0.5rem;"
          >
            <div>
              <h1
                class=" font-Roboto mr-10 leading-10 sm:truncate font-medium text-lg sm:tracking-tight flex-initial w-67 flex flex-row"
              >
                {$_("workspace.nom")}: {model.modelName}
              </h1>
              <h2 class="flex flex-row">
                {$_("workspace.Id Modèle")}:{model.idModel}

                <span
                  class="ml-2 cursor-pointer"
                  style="position: relative;"
                  on:click={copy(model.idModel)}
                >
                  <span id={model.idModel} style="display:none"
                    >{$_("workspace.copié")}!</span
                  >
                  <svg
                    width="22"
                    height="30"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 12.4V15.4C16 19.4 14.4 21 10.4 21H6.6C2.6 21 1 19.4 1 15.4V11.6C1 7.6 2.6 6 6.6 6H9.6"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6 1H14.6M6 4C6 2.34 7.34 1 9 1H11.62M21 7V13.19C21 14.74 19.74 16 18.19 16M16 12.4H12.8C10.4 12.4 9.6 11.6 9.6 9.2V6L16 12.4ZM21 7H18C15.75 7 15 6.25 15 4V1L21 7Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
            <div
              class="mt-2 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6"
            >
              <div class="mt-4 flex items-center text-sm text-gray-500">
                <div class="flex flex-col ml-4">
                  <span
                    class="text-sm text-gray-500 dark:text-gray-400 dateCreation"
                    >{$_("workspace.date")}
                  </span>
                  <span class="text-sm date"
                    >{model.creation_date} - {model.creation_time}</span
                  >
                </div>
                <!-- Heroicon name: mini/calendar -->
                <!-- <svg
                class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                  clip-rule="evenodd"
                />
              </svg>
              Date de creation {model.creation_date} - {model.creation_time} -->
              </div>
            </div>
            <div class="mt-5 flex flex-col lg:mt-0 lg:ml-4 w-40">
              <span
                class="updateBtn hidden sm:block ml-3 mb-2 modifyBtn"
                style="background-color:{privillegeTab.find(
                  (q) => q.id === privilegeData['UPDATE MODELS']
                )
                  ? ' #bf9fcf'
                  : '#DCDCDC'}"
                on:click={() => {
                  if (
                    privillegeTab.find(
                      (q) => q.id === privilegeData["UPDATE MODELS"]
                    )
                  ) {
                    navigate("Studio/" + model.idModel);
                  }
                }}
              >
                <!-- Heroicon name: mini/pencil -->
                {#if privillegeTab.find((q) => q.id === privilegeData["UPDATE MODELS"])}
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="5" fill="#945FAF" />
                    <path
                      d="M16.8882 8.24854H14.9131C9.97517 8.24854 8 10.2237 8 15.1616V21.0871C8 26.025 9.97517 28.0002 14.9131 28.0002H20.8386C25.7765 28.0002 27.7517 26.025 27.7517 21.0871V19.1119"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.8657 9.25544L14.0836 17.0376C13.7873 17.3339 13.491 17.9165 13.4318 18.3412L13.0071 21.3138C12.8491 22.3903 13.6095 23.1409 14.686 22.9927L17.6586 22.5681C18.0734 22.5088 18.6561 22.2125 18.9622 21.9163L26.7444 14.1341C28.0875 12.791 28.7196 11.2306 26.7444 9.25544C24.7692 7.28028 23.2088 7.91233 21.8657 9.25544V9.25544Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                {:else}
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="5" fill="#DCDCDC" />
                    <path
                      d="M16.8882 8.24854H14.9131C9.97517 8.24854 8 10.2237 8 15.1616V21.0871C8 26.025 9.97517 28.0002 14.9131 28.0002H20.8386C25.7765 28.0002 27.7517 26.025 27.7517 21.0871V19.1119"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.8657 9.25544L14.0836 17.0376C13.7873 17.3339 13.491 17.9165 13.4318 18.3412L13.0071 21.3138C12.8491 22.3903 13.6095 23.1409 14.686 22.9927L17.6586 22.5681C18.0734 22.5088 18.6561 22.2125 18.9622 21.9163L26.7444 14.1341C28.0875 12.791 28.7196 11.2306 26.7444 9.25544C24.7692 7.28028 23.2088 7.91233 21.8657 9.25544V9.25544Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                {/if}

                <p class="ml-3">{$_("workspace.modifier")}</p>
              </span>

              <span
                class="ml-3 hidden sm:block cursor-pointer mb-2 copyBtn"
                style="background-color:{privillegeTab.find(
                  (q) => q.id === privilegeData['CREATE MODELS']
                )
                  ? ' #FAF7FC'
                  : '#DCDCDC'}"
                on:click={() => handleOpen(model)}
              >
                {#if privillegeTab.find((q) => q.id === privilegeData["CREATE MODELS"])}
                  <svg
                    class="mr-3"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="5" fill="#F3EAF8" />
                    <path
                      d="M23 19.4V22.4C23 26.4 21.4 28 17.4 28H13.6C9.6 28 8 26.4 8 22.4V18.6C8 14.6 9.6 13 13.6 13H16.6"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.6 8H21.6M13 11C13 9.34 14.34 8 16 8H18.62M28 14V20.19C28 21.74 26.74 23 25.19 23M23 19.4H19.8C17.4 19.4 16.6 18.6 16.6 16.2V13L23 19.4ZM28 14H25C22.75 14 22 13.25 22 11V8L28 14Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                {:else}
                  <svg
                    class="mr-3"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="5" fill="#DCDCDC" />
                    <path
                      d="M23 19.4V22.4C23 26.4 21.4 28 17.4 28H13.6C9.6 28 8 26.4 8 22.4V18.6C8 14.6 9.6 13 13.6 13H16.6"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.6 8H21.6M13 11C13 9.34 14.34 8 16 8H18.62M28 14V20.19C28 21.74 26.74 23 25.19 23M23 19.4H19.8C17.4 19.4 16.6 18.6 16.6 16.2V13L23 19.4ZM28 14H25C22.75 14 22 13.25 22 11V8L28 14Z"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                {/if}

                <p
                  style="color:{privillegeTab.find(
                    (q) => q.id === privilegeData['CREATE MODELS']
                  )
                    ? '#945FAF '
                    : '#ffffff'}"
                >
                  {$_("workspace.copier")}
                </p>
              </span>
              <span
                class="ml-3 hidden sm:block cursor-pointer button"
                style="background-color:{privillegeTab.find(
                  (q) => q.id === privilegeData['DELETE MODELS']
                )
                  ? ' #ff3459'
                  : '#DCDCDC'}"
                on:click={() => {
                  if (
                    privillegeTab.find(
                      (q) => q.id === privilegeData["DELETE MODELS"]
                    )
                  ) {
                    handleOpenAlert(model.idModel);
                  }
                }}
              >
                {#if privillegeTab.find((q) => q.id === privilegeData["DELETE MODELS"])}
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="5" fill="#F9DBE0" />
                    <path
                      d="M14.8099 8L11.1899 11.63M21.1899 8L24.8099 11.63"
                      stroke="#FF3459"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 13.8501C8 12.0001 8.99 11.8501 10.22 11.8501H25.78C27.01 11.8501 28 12.0001 28 13.8501C28 16.0001 27.01 15.8501 25.78 15.8501H10.22C8.99 15.8501 8 16.0001 8 13.8501Z"
                      stroke="#FF3459"
                      stroke-width="1.5"
                    />
                    <path
                      d="M15.76 20V23.55M20.36 20V23.55M9.5 16L10.91 24.64C11.23 26.58 12 28 14.86 28H20.89C24 28 24.46 26.64 24.82 24.76L26.5 16"
                      stroke="#FF3459"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                {:else}
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="5" fill="#DCDCDC" />
                    <path
                      d="M14.8099 8L11.1899 11.63M21.1899 8L24.8099 11.63"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 13.8501C8 12.0001 8.99 11.8501 10.22 11.8501H25.78C27.01 11.8501 28 12.0001 28 13.8501C28 16.0001 27.01 15.8501 25.78 15.8501H10.22C8.99 15.8501 8 16.0001 8 13.8501Z"
                      stroke="#ffffff"
                      stroke-width="1.5"
                    />
                    <path
                      d="M15.76 20V23.55M20.36 20V23.55M9.5 16L10.91 24.64C11.23 26.58 12 28 14.86 28H20.89C24 28 24.46 26.64 24.82 24.76L26.5 16"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                {/if}

                <p class="ml-3">{$_("workspace.supprimer")}</p>
              </span>

              <!-- Dropdown -->
              <div class="relative ml-3 sm:hidden">
                <button
                  type="button"
                  class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  id="mobile-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  {$_("workspace.More")}
                  <!-- Heroicon name: mini/chevron-down -->
                  <svg
                    class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>

                <!--
                Dropdown menu, show/hide based on menu state.
        
                Entering: "transition ease-out duration-200"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
                <div
                  class="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="mobile-menu-button"
                  tabindex="-1"
                >
                  <!-- Active: "bg-gray-100", Not Active: "" -->
                  <!--  <a
                    href=""
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="mobile-menu-item-0">Edit</a
                  >

                  <a
                    href=""
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="mobile-menu-item-1">Delete</a
                  > -->
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>

      <!-- /End replace -->
    </div>
  </main>
</div>

<Modals>
  <div slot="backdrop" class="backdrop" on:click={closeModal} />
</Modals>

<style>
  .itemContainer {
    border: 2px solid #f0efef;
    margin-inline: 4rem;
  }
  .modifyBtn {
    display: inline-block;
    border: none;
    cursor: pointer;
    /* transition: transform 0.3s ease; */
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #bf9fcf;
    color: white;
    text-align: center;
    width: 7rem;
    border-radius: 0.5rem;
    width: 9.2rem;
  }

  .copyBtn {
    display: inline-block;
    border: none;
    cursor: pointer;
    /* transition: transform 0.3s ease; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* font-size: 1; */
    width: 7rem;
    color: #945faf;
    background-color: #faf7fc;
    border-radius: 0.5rem;
    width: 9.2rem;
  }

  .button {
    display: inline-block;
    border: none;
    cursor: pointer;
    /* transition: transform 0.3s ease; */
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ff3459;
    margin-left: 0.5rem;
    border-radius: 0.5rem;
    color: white;
  }
</style>
