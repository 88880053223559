<script>
  import { createEventDispatcher } from "svelte";
  import Option from "./Option.svelte";
  import SortableList from "svelte-sortable-list";

  let newItem = "";

  export let options;
  const dispatch = createEventDispatcher();
  function addToList() {
    if (newItem) {
      options = [...options, { id: options.length, text: newItem }];
      newItem = "";
    }
    dispatch("update", {
      options: options,
    });
  }

  function removeFromList(index) {
    options.splice(index, 1);
    options = options;
    dispatch("update", {
      options: options,
    });
  }

  const sortList = ev => {options = ev.detail};
</script>

<div class="mr-3 mt-2 flex-col items-center">
  <div style="margin: 5px;">
    <div
      class="m-2"
      style="display:flex;flex-direction:row;justify-content:space-between"
    >
      <input
        class="block appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        bind:value={newItem}
        type="text"
        placeholder="option"
      />
      <button on:click={addToList}>
        <svg
          class="mt-1 newChamp"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6 17H23.4M17 23.4V10.6M12.2 33H21.8C29.8 33 33 29.8 33 21.8V12.2C33 4.2 29.8 1 21.8 1H12.2C4.2 1 1 4.2 1 12.2V21.8C1 29.8 4.2 33 12.2 33Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    {#if options.length > 0}
      <h3 class="text-white" style="text-align: center;">
        les options de selections
      </h3>

      <div
        class="overflow-y-auto"
        style="display:flex;flex-direction:column;justify-content:center;align-items:center;height:fit-content;max-height:200px;margin-top:10px"
      >
        
      <SortableList list={options} key="id" on:sort={sortList} let:item let:index>
        <Option {item} {index}  on:click={() => removeFromList(index)} />
        </SortableList>
      </div>
    {/if}
  </div>
</div>


