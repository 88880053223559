<script>
  import {  createEventDispatcher } from "svelte";
  let rootElement;
  const dispatch = createEventDispatcher();
  let check = false
  export let height;
  $: rootElement &&
    rootElement.style.setProperty("--scale-checkbox", Math.round(height/21.48));
</script>
<div class="flex items-center justify-center" style="height: {height}px;">

  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" bind:this={rootElement} on:click={() => {
    check = !check
    dispatch("check", {
      state: check ? "True":"False",
    });
  }}>
</div>

<style>
  :root {
    --scale-checkbox: inherit;
  }
  .form-check-input{
    cursor: pointer;
  }
  #flexCheckDefault{
    height: 100%;
    width: 100%;
  }
  
</style>
