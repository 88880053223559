<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  export let item;
</script>

<div class="row">
  <div id="item"><h2>{item.text}</h2></div>
    <span style="cursor:pointer" on:click={() => dispatch("click")}>❌</span>
</div>

<style>
  .row {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-overflow: ellipsis;
    }
    #item {
      color: white;
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
</style>