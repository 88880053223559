function get_session_history() {
  return JSON.parse(sessionStorage.getItem("prev"));
}

function set_session_history(history) {
  sessionStorage.setItem("prev", JSON.stringify(history));
}

export function get_last_changes() {
  return JSON.parse(sessionStorage.getItem("last"));
}

function set_last_changes(history) {
  sessionStorage.setItem("last", JSON.stringify(history));
}

export function create_history(data) {
  let history = get_session_history();

  if (history && 20 > history.data.length > 0) {
    // console.log(history)
    if (
      JSON.stringify(history.data[history.data.length - 1]) !==
      JSON.stringify(data)
    ) {
      history.data.push(data);
      set_session_history(history);
    }
  } else if (history) {
    history.data.shift();
    history.data.push(data);
    set_session_history(history);
  } else {
    set_session_history({ data: [data] });
  }
}

export function delete_history(step) {
  let history = get_session_history();
  if (history.data.length > 0) {
    let deleted = history.data.pop();
    if (step === 0 ) set_last_changes(deleted)
    /* history.data.unshift(deleted) */
    set_session_history(history);
    return history.data[history.data.length - 1];
  }
}


