<script>
  import { onMount } from "svelte";
  import Portal from "./Portal.svelte";
  import { StartSideBarTutorial } from "../utils/tutorial";
  onMount(() => {
    setTimeout(() => {
      StartSideBarTutorial();
    }, 500);
  });
</script>

<Portal>
  <div class="fixed z-10 top-0 left-0 right-0 h-30">
    <slot />
  </div>
</Portal>
