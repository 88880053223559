<script>
  import { closeModal } from "svelte-modals";
  import Swal from "sweetalert2";
  import { _ } from "../../services/i18n"
  export let addSection;
  export let isOpen;
  let Nom = "";
  let Niveau;
</script>

{#if isOpen}
  <div role="dialog" class="modal" id="modal">
    <div class="contents">
      <div
        class="relative z-10"
        aria-labelledby="modal-title"   
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg items-center"
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="">
                  <div
                    class="mt-3 text-center sm:mt-0  flex-col justify-center items-center centerClass"
                  >
                    <h1
                      class="purpleColor text-sm font-medium leading-10 text-gray-900"
                      id="modal-title"
                    >
                    {$_('workspace.Creation de section')}
                    </h1>
                    <div class="flex justify-center">
                      <div class="mb-3 xl:w-96">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label inline-block mb-2 text-gray-700"
                        >
                          <div>{$_('workspace.nom')} <span style="color: red;">*</span></div>
                        </label>
                        <input
                          type="text"
                          bind:value={Nom}
                          class="
                              form-control
                              block
                              w-full
                              px-3
                              py-1.5
                              text-base
                              font-normal
                              text-gray-700
                              bg-white bg-clip-padding
                              border border-solid border-gray-300
                              rounded
                              transition
                              ease-in-out
                              m-0
                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            "
                          id="exampleFormControlInput1"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="flex justify-center">
                      <div class="mb-3 xl:w-96">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label inline-block mb-2 text-gray-700"
                          >{$_('workspace.Niveau')}</label
                        >
                        <input
                          bind:value={Niveau}
                          type="number"
                          class="
                                form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                              "
                          id="exampleFormControlInput1"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <p class="size">{$_("workspace.Merci d'identifier les champs")}</p>
                  </div>
                </div>

                <div class=" centerClass  py-3  ">
                  <button
                    type="button"
                    on:click={closeModal}
                    class="annulerBtn mt-3  w-full  rounded-md  px-4 py-2  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >{$_('workspace.annuler')}</button
                  >
                  <button
                    type="button"
                    on:click={() => {
                      if (Nom !== "") {
                        addSection(Nom, Niveau);
                        closeModal();
                      } else {
                        Swal.fire($_('workspace.nom')+"?", $_('workspace.Nom est obligatoire'), "question");
     
                      }
                    }}
                    class="confirmerBtn mt-3  w-full  rounded-md b px-4 py-2   sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >{$_('workspace.confirmer')}</button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    /* allow click-through to backdrop */
    pointer-events: none;
  }

  .contents {
    min-width: 240px;
    border-radius: 6px;
    padding: 16px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: auto;
  }

  h2 {
    text-align: center;
    font-size: 24px;
  }

  p {
    text-align: center;
    margin-top: 16px;
  }
  .centerClass {
    text-align: -webkit-center;
  }
  .btn {
    margin: 2rem;
    background-color: #d9d9d9;
    color: #7e7e7e;
    font-weight: 500;
    font-size: 16px;
    font-family: Roboto;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 60%;
  }
  .size {
    color: #7e7e7e;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
  }
  .purpleColor {
    color: #945faf;
  }
  .annulerBtn {
    background-color: #7e7e7e;
    color: white;
  }
  .confirmerBtn {
    background-color: #945faf;
    color: white;
  }
</style>
